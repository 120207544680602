import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/create-task',
    name: 'createTask',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/create-task.vue'),
    meta: {
      title: '创建任务'
    }
  },
  {
    path: '/data/index',
    name: 'dataIndex',
    component: () => import(/* webpackChunkName: "task" */ '@/views/data/index.vue'),
    meta: {
      title: '数据中心'
    }
  },
  {
    path: '/data/shortcuts',
    name: 'dataShortcuts',
    component: () => import(/* webpackChunkName: "task" */ '@/views/data/shortcuts.vue'),
    meta: {
      title: '快捷入口'
    }
  },
]

export default routes